import React from "react";
import styled from "@emotion/styled";

// Styling
const Root = styled.div``;

// Markup
const NotFoundPage = () => {
    return <p>not found</p>;
};

export default NotFoundPage;
